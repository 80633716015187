<style>
  .carousel {
    display: flex;
    justify-content: center;
  }

  mdb-carousel {
    max-width: 1000px;
    width: 100%;
  }

  .no-list {
    list-style-type: none;
  }

  .row {
    display: flex;;
  }
  
  .skills-list img {
    max-width: 250px;
    background-repeat: no-repeat;
  }

  .skills-list li {
    list-style-type: none;
  }

  @media screen and (min-width: 650px) {
    ul.line {
      display: flex;
      justify-content: center;
    }

    img {
      filter: brightness(0.80);
    }

    .skills-list li {
      padding: 0px 5px 0px 0px;
    }

  }

  @media screen and (min-width: 850px) {
    ul.line {
      display: flex;
      justify-content: center;
    }
  }

</style>
<main class="main page">
  <section class="carousel">
    <mdb-carousel [controls]="true" [indicators]="true" [interval]="10000">
      <mdb-carousel-item>
        <img src="/assets/carousel/slider-4.png" class="d-block w-100" alt="..." />
        <div class="carousel-caption d-none d-md-block intro">
          <h5 i83n>Inspirando los sentidos desde 1976.</h5>
          <p i83n>En Aromaven Sabores nos esforzamos por ser esencial para el éxito de nuestros clientes..</p>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <img src="/assets/carousel/slider-1.png" class="d-block w-100" alt="..." />
        <div class="carousel-caption d-none d-md-block">
          <h5 i83n>Dinámico y en evolución siempre</h5>
          <p i83n>Nos preocupamos por proveer una experiencia positiva para nuestros clientes..</p>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <img src="/assets/carousel/slider-6.png" class="d-block w-100" alt="..." />
        <div class="carousel-caption d-none d-md-block">
          <h5 i83n>Inspiración a través de la innovación</h5>
          <p i83n>Destacamos la creatividad y colaboración como fórmula para el éxito..</p>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <img src="/assets/carousel/slider-3.png" class="d-block w-100" alt="..." />
        <div class="carousel-caption d-none d-md-block">
          <h5 i83n>Creatividad + Colaboración = ÉXITO</h5>
          <p i83n>Nuestra experiencia con los potenciadores y la tecnología de refrigeración nos han permitido diseñar sabores..</p>
        </div>
      </mdb-carousel-item>
      <mdb-carousel-item>
        <img src="/assets/carousel/carnes1.jpeg" class="d-block w-100" alt="..." />
        <div class="carousel-caption d-none d-md-block">
          <h5 i83n>Garantizado</h5>
          <p i83n>
            Mejora del sabor.
            Mejora la dulzura.
            Mejora sensación en la boca.
            Sabor natural.
            Sensación refrescante.
            Disminución de la amargura
          </p>
        </div>
      </mdb-carousel-item>
    </mdb-carousel>
  </section>
  <section class="content">
    <h2 i83n>¡Bienvenido!</h2>
    <p i83n>
      Aromaven es reconocido como un proveedor de confianza del mercado medio durante más de {{years}} años.
      Este sitio web les ofrece la oportunidad de conocernos un poco más, leer nuestra historia, conocer nuestra gama de productos y aplicaciones, y comunicarse con nosotros para cualquier duda o interés. Es un placer que visite nuestro sitio web, esperamos saber de usted pronto.
    </p>
    <h2 i83n>Aromaven excede sus expectativas del sabor.</h2>
    <ul class="line skills-list">
      <li>
        <h3 i83n>Creatividad + Colaboración = ÉXITO.</h3>
        <p i83n>
          Nuestra experiencia con los potenciadores y la tecnología de refrigeración nos han permitido diseñar sabores que abarcan dulces, cítricos y frutas, productos vegetales, lácteos y salados.
        </p>
      </li>
      <li>
        <h3 i83n>Inspiración a través de la innovación.</h3>
        <p i83n>
          Destacamos la creatividad y colaboración como fórmula para el éxito. Nuestro objetivo es resolver los retos de nuestros clientes y establecer nuevos estándares a través de la combinación de arte y ciencia.
        </p>
      </li>
      <li>
        <h3 i83n>¡Dinámico y en evolución siempre!</h3>
        <p i83n>
          Nos preocupamos por proveer una experiencia positiva para nuestros clientes mediante el fomento de un enfoque de colaboración. El talento y el entusiasmo, es nuestro recurso más valioso.
        </p>
      </li>
      <li>
        <h3 i83n>Inspirando los sentidos desde 1976.</h3>
        <p i83n>
          En Aromaven Sabores nos esforzamos por ser esencial para el éxito de nuestros clientes, ofreciendo soluciones innovadoras que apoyan la creación y elaboración de sus productos.
        </p>
      </li>
    </ul>
    <div>
    <!-- <div style="width: 100%;display: flex;justify-content: center;"> -->
      <h2 i83n> ¡Garantizado! </h2>
    </div>
    <!-- <app-divider></app-divider> -->
    <!-- <div style="display: flex;justify-content: center; width: 100%;"> -->
    <div>
      <ul>
        <li i83n>
          Mejora del sabor.
        </li>
        <li i83n>
          Mejora la dulzura.
        </li>
        <li i83n>
          Mejora sensación en la boca.
        </li>
        <li i83n>
          Sabor natural.
        </li>
        <li i83n>
          Sensación refrescante.
        </li>
        <li i83n>
          Disminución de la amargura.
        </li>
        <li i83n>
          Disminución de la sequedad.
        </li>
        <li>
          Nivel microbiológico conforme con Norma Venezolana Covenin
        </li>
      </ul>
    </div>

    <!-- <div style="width: 100%;display: flex;justify-content: center;"> -->
    <div>
      <h2 i83n> Comprometidos contigo y tus productos.  </h2>
    </div>
    <!-- <app-divider></app-divider> -->
    <div>
    <!-- <div style="display: flex;justify-content: center; width: 100%;"> -->
      <ul>
        <li i83n>
          Nuestro objetivo es resolver los retos de nuestros clientes.
        </li>
        <li i83n>
          Estableciendo nuevos estándares.
        </li>
        <li i83n>
          Innovando al combinar Arte y Ciencia.
        </li>
        <li i83n>
          Experiencia en alimentos principales y bebidas.
        </li>
        <li i83n>
          Expertos en enmascarar y mejorar productos enrriquecidos.
        </li>
      </ul>
    </div>
  </section>
</main>

